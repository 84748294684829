import React from "react"
import { Link } from "gatsby"
import IconStar from "../components/IconStar"

function PostNav({ next, prev, ...props }) {
  return (
    <div
      className={`md:flex md:justify-between md:items-center border-t-2 pt-48 mt-48 border-tan-100 ${
        props.className ? props.className : ""
      }`}
      {...props}
    >
      {prev && (
        <Link to={prev.fields.slug} rel="prev">
          <div className="flex justify-start space-x-8 group mb-24 md:mb-0">
            <span className="font-geared text-lg text-red-200 leading-none group-hover:text-red-300 hover:underline">
              &lt;
            </span>

            <h3 className="font-geared text-lg text-gray-400 leading-none group-hover:text-red-300 hover:underline">
              {prev.frontmatter.title}
            </h3>
          </div>
        </Link>
      )}

      <IconStar className="hidden md:block w-24 text-tan-100" />

      {next && (
        <Link to={next.fields.slug} rel="next">
          <div className="flex justify-start space-x-8 group mb-24 md:mb-0">
            <h3 className="font-geared text-lg text-gray-400 leading-none group-hover:text-red-300 hover:underline">
              {next.frontmatter.title}
            </h3>

            <span className="font-geared text-lg text-red-200 leading-none group-hover:text-red-300 hover:underline">
              &gt;
            </span>
          </div>
        </Link>
      )}
    </div>
  )
}

export default PostNav
