import React from "react"
import { DateTime } from "luxon"

const ordinal = number => {
  return number > 20 || number < 10
    ? [false, "st", "nd", "rd"][number % 10] || "th"
    : "th"
}

function Date({ date, ...props }) {
  const luxonDate = DateTime.fromFormat(date, "MMMM dd, yyyy")
  const dayOrdinal = ordinal(luxonDate.day)

  return (
    <time
      {...props}
      dateTime={luxonDate.toISO()}
      className={`text-right leading-none block ${
        props.className ? props.className : ""
      }`}
    >
      <div className="flex justify-end items-baseline space-x-4">
        <div className="font-geared uppercase text-blue-400 text-lg">
          {luxonDate.monthShort}
        </div>

        <div className="font-geared uppercase text-blue-300 text-xl">
          {luxonDate.toFormat("dd")}
        </div>

        <div className="font-geared uppercase text-blue-200 text-lg">
          {dayOrdinal}
        </div>
      </div>

      <div className="font-geared uppercase text-blue-200 text-lg">
        {luxonDate.year}
      </div>
    </time>
  )
}

export default Date
