import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Date from "../components/Date"
import PostNav from "../components/PostNav"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <article>
          <div className="mb-24">
            <Date date={post.frontmatter.date} className="mb-16" />

            <h1 class="text-2xl md:text-3xl leading-none text-gray-400 font-geared tracking-tight">
              {post.frontmatter.title}
            </h1>

            <p className="text-base font-thin">
              By: <span className="font-bold italic">Thomas Manley</span>
            </p>
          </div>

          <p className="font-thin text-lg mb-16 text-blue-400">
            {post.frontmatter.description}
          </p>

          <div
            className="post"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </article>

        {(next || previous) && (
          <PostNav next={next || undefined} prev={previous || undefined} />
        )}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
